<template>
	<div class="settings">
		<h1>Settings</h1>

		<div v-if="message" class="alert alert-primary">{{ message }}!</div>

		<form name="frmSettings" id="frmSettings">
			<label for="monetaryGoal">Monetary Goal</label>
			<input
				type="number"
				id="monetaryGoal"
				v-model.number="settings.monetaryGoal"
				ref="monetaryGoal"
			/>

			<button type="button" @click="onSaveClick">Save</button>
		</form>

		<button
			class="upload-raffles-button floating-button"
			@click="goToUploadRafflesPage"
			alt="Upload raffle CSV"
			title="Upload raffle CSV"
		>
			<font-awesome-icon icon="upload"></font-awesome-icon>
		</button>

		<button
			class="upload-prizes-button floating-button"
			@click="goToUploadPrizesPage"
			alt="Upload prizes CSV"
			title="Upload prizes CSV"
		>
			<font-awesome-icon icon="award"></font-awesome-icon>
		</button>

		<button
			class="delete-winners-button floating-button"
			@click="deleteWinners"
			alt="Delete all winners"
			title="Delete all winners"
		>
			<font-awesome-icon icon="backspace"></font-awesome-icon>
		</button>

		<button
			class="home-button floating-button"
			@click="goHome"
			alt="Go to the home page"
			title="Go to the home page"
		>
			<font-awesome-icon icon="home"></font-awesome-icon>
		</button>
	</div>
</template>

<style scoped>
.upload-raffles-button {
	bottom: 250px;
}

.upload-prizes-button {
	bottom: 180px;
}

.delete-winners-button {
	bottom: 110px;
}

.home-button {
	bottom: 40px;
}
</style>

<script>
import * as axios from "axios";

export default {
	name: "Settings",

	data() {
		return {
			message: "",
			settings: {
				monetaryGoal: 0.0,
			},
		};
	},

	async created() {
		this.settings = await this.getSettings();
	},

	mounted() {
		this.$refs.monetaryGoal.focus();
	},

	methods: {
		async deleteWinners() {
			if (confirm("Are you sure you want to delete all winners?") === true) {
				await axios.delete(`/winners`);
				alert("Done.");
			}
		},

		async getSettings() {
			const result = await axios.get(`/settings`);
			return result.data;
		},

		goHome() {
			this.$router.push({ name: "Home" });
		},

		goToUploadPrizesPage() {
			this.$router.push({ name: "UploadPrizes" });
		},

		goToUploadRafflesPage() {
			this.$router.push({ name: "UploadRaffles" });
		},

		async onSaveClick() {
			await axios.put(`/settings`, this.settings);
			this.message = "Update successful.";
		},
	},
};
</script>
